<template>
  <v-card class="access-tile ma-2">
    <v-card-title @click.stop="allowSetAccess ? show = !show : !show">
      <h3 v-if="grantAccessStep" class="success--text">Grant Access</h3>
      <h3 v-else-if="revokeAccessStep" class="error--text">Revoke Access</h3>
      <h3 v-else>User Access Settings</h3>
      <v-icon class="tooltip-icon ml-2" v-tooltip="{ content: 'Grant or revoke access of a user<br>to a single or multiple devices.', trigger: 'click hover' }">info</v-icon>
      <v-spacer></v-spacer>
      <v-btn flat small icon class="ma-0" @click.stop="show = !show" :disabled="!allowSetAccess">
        <v-icon v-if="show">keyboard_arrow_up</v-icon>
        <v-icon v-else>keyboard_arrow_down</v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="show">
      <v-alert :id="getErrorId()" :value="error" type="error" dismissible @input="dismissAlert">{{ error }}</v-alert>
      <v-card-text class="pb-0">
        <div v-if="!grantAccessStep && !revokeAccessStep">
          <p class="subheading text-xs-justify ">
            User access settings is only applicable to devices in the "Smart" power setting.
          </p>
          <v-text-field
            v-model="username"
            label="User's E-mail"
            outline
            :append-icon="plugsLoaded ? 'close' : ''"
            @click:append="
              username = null;
              userLoaded = false;
            "
            @keyup.stop.enter="userSearch()"
            :readonly="plugsLoaded ? true : false"
            autocomplete="false"
            ref="plugBox"
            :error="error || userError ? true : false"
            :disabled="userLoading || plugsLoading"
            class="mb-0"
          />
          <v-select v-model="access" :items="['Grant', 'Revoke']" label="Access" outline :error="error || userError ? true : false" :disabled="userLoading || plugsLoading" />
          <v-autocomplete
            v-show="access === 'Grant'"
            v-model="plug_payment_access_plan_id"
            :items="visiblePayments"
            item-text="name"
            item-value="id"
            label="Payment Plan"
            no-data-text="No payment plans available"
            outline
            :error="error ? true : false"
            :disabled="userLoading || plugsLoading"
          />
        </div>
        <div v-else>
          User:
          <strong>{{ username }}</strong>
          <div v-if="grantAccessStep">
            Payment Plan:
            <strong>{{ selectedPlan.params.description }}</strong>
            <br />Currency:
            <strong>{{ selectedPlan.wallet.currency }}</strong>
            <br />Activation Fee:
            <strong>{{ formatCost(selectedPlan.params.startSessionCost) }}</strong>
            <br />Duration Rate:
            <strong>{{ formatRate(selectedPlan.params.hRate) }}</strong>
            <br />Consumption Rate:
            <strong>{{ formatRate(selectedPlan.params.whRate, "kWh") }}</strong>
          </div>
          <div>
            <v-text-field
              max-width="300"
              class="mb-0 mx-2"
              v-model.lazy="search"
              :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
              autocomplete="false"
              prepend-icon="search"
            >
              <template v-if="enableRegexSearch" v-slot:prepend-inner>
                <v-flex>
                  <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
                </v-flex>
              </template>
              <template v-slot:append>
                <v-flex align-self-center class="search-append-div">
                  <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

                  <v-icon
                    @click="enableRegexSearch = !enableRegexSearch"
                    class="tooltip-icon"
                    :color="enableRegexSearch ? `blue lighten-2` : ''"
                    v-tooltip="{
                      content: 'Allows search results to be filtered using regular expression (RegEx) patterns. RegEx flavour is JavaScript/ECMAScript.',
                      trigger: 'hover',
                    }"
                    >settings_applications</v-icon
                  >
                </v-flex>
              </template>
            </v-text-field>
          </div>
          <template v-if="isPlugShowed && userPlugs">
            <div class="text-xs-right mb-2 p-relative">
              <a class="d-inline-block mr-2 select-all" @click.stop.prevent="selectAll = !selectAll">{{ selectAll ? "Select None &nbsp;&nbsp;" : "Select All &nbsp;&nbsp;" }}</a>
              <v-checkbox
                class="d-inline-block mt-0 mb-0 mr-1"
                style="padding-right: 1px"
                :color="access === 'Grant' ? 'green' : 'red'"
                v-model="selectAll"
                :disabled="userLoading || plugsLoading"
                hide-details
              />
            </div>

            <v-list class="pa-0 ma-0 mt-0 mb-2 access-tile-plugs" :key="key">
              <v-list-tile v-for="(plug, index) in userPlugs" :key="index" class="ma-0 access-tile-plugs-list" v-show="plug.show" @click="(evt) => clickCheckbox(evt, index)">
                <v-list-tile-content>
                  <v-list-tile-title class="user-name">{{ plug.identifier + " - " + plug.description }}</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action class="text-xs-right">
                  <div style="checkbox-container">
                    <v-checkbox
                      :id="`checkbox_${index}`"
                      :key="search + '_' + key"
                      :color="access === 'Grant' ? 'green' : 'red'"
                      v-model="plug.selected"
                      @change="select(plug)"
                      :disabled="userLoading || plugsLoading"
                      hide-details
                    />
                  </div>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </template>
          <div v-else class="text-xs-center">
            <p class="subheading">No Devices</p>
            <p v-if="grantAccessStep">
              User <strong>{{ username }}</strong> may already have access to all your registered devices
            </p>
            <p v-if="revokeAccessStep">
              User <strong>{{ username }}</strong> may already have permissions revoked for all your registered devices
            </p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="pa-3">
        <v-btn v-if="userLoaded" @click="back()" round outline small>BACK</v-btn>
        <send-email v-if="showSendEmailButton" :username="username" />
        <v-spacer></v-spacer>
        <v-btn color="green" v-if="!grantAccessStep && !revokeAccessStep" @click="userSearch()" :loading="userLoading || plugsLoading" round outline small>NEXT</v-btn>
        <v-btn 
          :id="grantAccessStep ? `user-grant-access-button` : `user-revoke-access-button`" 
          v-else-if="userPlugs.length" 
          :color="grantAccessStep ? 'green' : 'red'" 
          @click="confirmAccess()" 
          :loading="userLoading || plugsLoading" round outline small
        >
          {{
            grantAccessStep ? "GRANT ACCESS" : "REVOKE ACCESS"
          }}
        </v-btn>
      </v-card-actions>
    </div>
    <v-dialog v-model="confirmDialog" max-width="320">
      <v-card>
        <v-card-title>
          <h3>Confirm</h3>
        </v-card-title>
        <v-card-text>{{ grantAccessStep ? "Grant" : "Revoke" }} user access to selected device(s)?</v-card-text>
        <v-card-actions>
          <v-btn flat @click="confirmDialog = false">CANCEL</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" flat @click="processAccess()" :loading="userLoading || plugsLoading">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OwnerProxyHelpers } from "@/library/helpers";
import sort from "./../mixins/sort";
import validators from "./../mixins/validators";
import helpers from "./../mixins/helpers";
import select from "./../mixins/select";
import SendEmail from "./buttons/SendEmail";
import Api from "@library/apis/Api";
import { cloneDeep as _cloneDeep } from "lodash-es";

export default {
  mixins: [sort, validators, helpers, select],
  components: {
    SendEmail,
  },
  data() {
    return {
      access: null,
      userPlugsData: null,
      plug_payment_access_plan_id: null,
      plugId: null,
      plugIdentifier: null,
      username: null,
      plugsLoading: false,
      userLoaded: false,
      userValid: false,
      busy: false,
      userError: false,
      error: null,
      confirmDialog: false,
      key: "users-plugs",
      show: false,
      enableRegexSearch: false,
      search: null,
    };
  },
  computed: {
    ...mapState({
      plugs: (state) => state.plugs,
      role: (state) => state.Auth.role,
      managerview: (state) => state.Admin.managerview,
    }),
    ...mapGetters(["accessPlans"]),
    ...mapGetters("PaymentPlan", { 
      hiddenPayments: "hiddenPayments",
      visiblePayments: "visiblePayments"
    }),
    allowSetAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_SET_USER_ACCESS");
    },
    grantAccessStep() {
      return this.userLoaded && this.access === "Grant" && this.plug_payment_access_plan_id;
    },
    revokeAccessStep() {
      return this.userLoaded && this.access === "Revoke";
    },
    user() {
      return {
        username: this.username,
        plug_payment_access_plan_id: this.plug_payment_access_plan_id,
      };
    },
    plug() {
      return {
        id: this.selectedPlug ? this.selectedPlug.id : null,
      };
    },
    userPlugs() {
      let user = this.userPlugsData || [];
      let plugs = JSON.parse(JSON.stringify(this.plugs)) || [];
      let newUser = [];
      let newList = [];
      let tempPlugs = [];

      newUser = user.map((o) => {
        o.selected = this.selectAll;
        o.show = true;
        tempPlugs.push(o.identifier);
        o.description = "";
        const selected_plug = this.plugs.filter((plug) => plug.id == o.plug_id)[0];
        if (typeof selected_plug != "undefined") o.description = selected_plug.description;
        return o;
      });

      newList = plugs.map((o) => {
        o.selected = this.selectAll;
        tempPlugs.indexOf(o.identifier) === -1 ? (o.show = true) : (o.show = false);
        return o;
      });

      if (this.access === "Revoke") newList = newUser.sort(this.compareValues("identifier"));

      return newList.filter((o) => {
        if (this.search === null) return true;
        if (!this.enableRegexSearch) {
          const search = this.search.toLowerCase();
          const device = (o.identifier + " - " + o.description).toLowerCase();
          return device.includes(search);
        } else {
          try {
            const search = new RegExp(this.search, "");
            const device = o.identifier + " - " + o.description;
            return device.match(search);
          } catch (error) {
            console.log(error.message);
            return false;
          }
        }
      });
    },
    plugsList() {
      let plugs = [];
      this.plugs.forEach((o) => {
        plugs.push(o.identifier);
      });
      return plugs;
    },
    selectedPlug() {
      return this.plugs.find((o) => {
        return this.plugIdentifier === o.identifier;
      });
    },
    selectedPlan() {
      return this.accessPlans.find((o) => {
        return this.plug_payment_access_plan_id === o.id;
      });
    },
    plugsLoaded() {
      return this.username && this.userLoaded;
    },
    plugCount() {
      return this.plugs.length;
    },
    userLoading() {
      return this.$store.state.loading;
    },
    showSendEmailButton() {
      if (this.error && this.error === "User does not exist") {
        return this.username;
      }
      return false;
    },
    isPlugShowed() {
      if (!this.userPlugs.length) return false;
      return this.userPlugs.some((userPlug) => userPlug.show === true);
    },
  },
  watch: {
    userPlugs() {
      this.selected = [];
    },
    username() {
      this.$nextTick(() => (this.username = String((this.username || "").replace(" ", "").toLowerCase())));
    },
    search(value) {
      this.selectAll = false;
      this.selected = [];
    },
  },
  methods: {
    getErrorId() { 
      if (this.error === 'Could not complete requests. Please try again' && this.access === "Grant") return `error-grant-access`;
      if (this.error === 'Could not complete requests. Please try again' && this.access === "Revoke") return `error-revoke-access`;
      else return '' 
    },
    clickCheckbox(evt, index) {
      const automaticallyClickCheckbox =
        evt.target.classList.contains("v-list__tile__title") ||
        evt.target.classList.contains("v-list__tile") ||
        evt.target.classList.contains("v-list__tile__action") ||
        evt.target.classList.contains("v-list__tile__content");
      if (automaticallyClickCheckbox) document.getElementById(`checkbox_${index}`).click();
    },
    userSearch() {
      this.error = false;
      if (!this.username || !this.access) {
        this.userError = true;
        return false;
      }

      if (this.access === "Grant" && !this.plug_payment_access_plan_id) {
        this.error = "Please select Payment Plan";
        return false;
      }

      this.plugsLoading = true;

      const formData = { username: this.username };
      if (this.role == "admin") formData.ownerUsername = this.managerview.owner_username;
      Api.userPlugs(this.role, formData)
        .then((userPlugs) => {
          this.userError = false;
          this.plugsLoading = false;
          this.userLoaded = true;
          this.userPlugsData = userPlugs;
        })
        .catch((error) => {
          this.plugsLoading = false;
          this.error = error.response.data;
        });
    },
    confirmAccess() {
      if (!this.selected.length) {
        this.error = "Please select at least 1 device";
        return false;
      }

      this.confirmDialog = true;
    },
    processAccess() {
      this.error = false;
      this.$store.dispatch("loading", true);

      let api = [];

      this.selected.forEach((e) => {
        let data = `{
          "username":"${this.username}",
          "plugId":${e},
          "plugPaymentAccessPlanId":${this.plug_payment_access_plan_id}}
        `;
        api.push(Api.setUserAccessToPlug(this.role, this.access !== "Revoke", data));
      });

      Promise.all(api)
        .then(() => {
          this.$store.dispatch("Plug/refreshPlugCards", _cloneDeep(this.selected));
          this.$store.dispatch("snackbar", {
            message: this.grantAccessStep ? "User was given access to plugs" : "User access to plugs was revoked",
          });
          this.back();
          this.reset();
        })
        .catch((error) => {
          this.key = this.$moment().unix();
          this.userSearch();
          this.error = "Could not complete requests. Please try again";
          console.log(error);
        })
        .finally(() => {
          this.confirmDialog = false;
          this.$store.dispatch("loading", false);
        });
    },
    back() {
      this.userLoaded = false;
      this.userPlugsData = null;
      this.selected = [];
      this.selectAll = false;
      this.search = null;
    },
    reset() {
      this.plug_payment_access_plan_id = null;
      this.access = null;
      this.plugIdentifier = null;
      this.username = null;
    },
    dismissAlert(evt) {
      if (!evt) this.error = false;
    },
  },
  mounted () { 
    if (this.hiddenPayments.includes(this.plug_payment_access_plan_id)) {
      this.plug_payment_access_plan_id = null
    }
  },
};
</script>

<style lang="scss">
.search-slash-icon {
  color: rgba(0, 0, 0, 0.87);
}
.search-append-div {
  white-space: nowrap;
}

.access-tile-plugs {
  border: 1px solid #f2f2f2;
  border-top: none;
}

.access-tile-plugs-list {
  .v-list__tile {
    border-top: 1px solid #f2f2f2;
    padding-right: 4px;
    cursor: default;
  }
}

.checkbox-container {
  width: 34px;
}

.select-all {
  position: absolute;
  right: 32px;
  top: 6px;
}
</style>
