<template>
  <v-layout row wrap>
    <v-flex xs6 class="pt-2" style="margin-top: 2px">
      <strong>Transaction History</strong>
    </v-flex>
    <v-flex xs6 class="pb-3 text-xs-right">
      <div class="transaction-filter">
        <v-menu offset-y>
          <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              SORT: &nbsp;
              <strong>{{ filter.sorting.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
          </v-btn>
          <v-list hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.sorting.property === item.property }"
              v-for="(item, index) in filter.sort"
              :key="index"
              @click="filter.sorting = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat small style="min-width: auto" class="mr-0" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
          <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
        </v-btn>
      </div>
    </v-flex>
    <v-flex xs12>
      <v-expansion-panel v-model="expand" class="mt-2">
        <v-expansion-panel-content>
          <template v-slot:header>
            <v-layout xs12 align-center>
              <span class="flex xs4">
                <strong>{{ transactionHistory.length }}</strong> {{ transactionHistory.length > 1 ? `Transactions` : "Transaction" }}
              </span>

              <h3 class="flex xs4 error--text">{{ totalWithdrawal | numeral("0,0.00") }}</h3>

              <h3 class="flex xs4 success--text">{{ totalDeposit > 0 ? "+" : "" }}{{ totalDeposit | numeral("0,0.00") }}</h3>
            </v-layout>
          </template>
          <v-list v-if="!isLoading" two-line class="pt-0 pb-0 wallet-transaction__wrapper">
            <template v-if="transactionHistory.length > 0">
              <v-list-tile class="transaction" v-for="(transaction, index) in transactionHistory" :key="index" @click="transactionClick(transaction)">
                <v-list-tile-content>
                  <v-list-tile-title class="date">{{ transaction.record_timestamp_text }}</v-list-tile-title>
                  <v-list-tile-sub-title>
                    <small>{{ transaction.memo }}</small>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-list-tile-action-text>
                    <h2 :class="transaction.class">
                      {{ transaction.money | numeral("0,0.00") }}
                    </h2>
                  </v-list-tile-action-text>
                </v-list-tile-action>
              </v-list-tile>
              <v-list-tile @click="$emit('loadMoreTransactionClicked')">
                <v-list-tile-content>
                  <v-list-tile-title class="date font-weight-medium" style="text-align:center;">Load More Transactions</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
            <v-list-tile v-else @click="$emit('loadMoreTransactionClicked')">
              <v-list-tile-content>
                <v-list-tile-title class="date font-weight-medium" style="text-align:center;">No recent transactions</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
  </v-layout>
</template>

<script>
import Api from "./../library/apis/Api";
import { mapState } from "vuex";
export default {
  props: {
    walletId: {
      required: true,
    },
    currency: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rawTransactions: [],
      transactionsLoaded: false,
      isLoading: false,
      actionSub: null,
      filter: {
        desc: true,
        sorting: {
          label: "DATE",
          property: "record_timestamp",
        },
        sort: [
          {
            label: "AMOUNT",
            property: "value",
          },
          {
            label: "DATE",
            property: "record_timestamp",
          },
        ],
      },
      total: 0,
      expand: 0,
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      timezone: (state) => state.Global.timezone,
    }),
    storedTime() {
      return this.$store.getters.storedTime;
    },
    transactionHistory() {
      let transactions = this.rawTransactions.map((o) => {
        o.record_timestamp_text = this.$moment
          .unix(o.record_timestamp)
          .tz(this.timezone)
          .format("ll");
        o.class = o.credit ? "success--text" : "error--text";
        if (o.credit) {
          o.money = `+${o.credit}`;
        } else {
          if (o.debit) {
            o.money = `-${o.debit}`;
          } else {
            o.money = 0;
            o.class = "black--text";
          }
        }
        o.session_id = this.getSessionId(o);
        o.value = parseFloat(o.money);
        return o;
      });

      let order = this.filter.sorting.property;

      if (order) {
        if (this.filter.desc) {
          transactions.sort((a, b) => b[order] - a[order]);
        } else {
          transactions.sort((a, b) => a[order] - b[order]);
        }
      }

      return transactions;
    },
    totalDeposit() {
      const positiveTransactions = this.transactionHistory.filter((t) => t.value > 0);
      const total = positiveTransactions.length > 0 ? positiveTransactions.map((t) => t.value).reduce((a, b) => a + b) : 0;
      return total > 0 ? total : "0.00";
    },
    totalWithdrawal() {
      const negativeTransactions = this.transactionHistory.filter((t) => t.value < 0);
      const total = negativeTransactions.length > 0 ? negativeTransactions.map((t) => t.value).reduce((a, b) => a + b) : 0;
      return total < 0 ? total : "0.00";
    },
  },
  methods: {
    getTransactions() {
      this.transactionsLoading = true;
      this.transactionsLoaded = false;
      this.isLoading = true;
      setTimeout(() => {
        const formData = { walletId: this.walletId, since: this.storedTime.since, till: this.storedTime.till };
        Api.walletTransactions(this.role, formData)
          .then((data) => {
            if (data && data.length) this.rawTransactions = data.reverse();
            else this.rawTransactions = [];
            this.rawTransactions.sort((a, b) => a.record_timestamp - b.record_timestamp);

            this.$store.dispatch("setSpesificWalletTransactions", {
              walletId: this.walletId,
              transactions: this.rawTransactions,
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    getSessionId(obj) {
      if (obj && obj.memo) {
        let memo = obj.memo.split(" ");
        let id = parseInt(memo[1]);
        if ((memo[0] === "Session" || memo[0] === "Sesssion") && id) {
          return id;
        }
      }
      return false;
    },
    transactionClick(transaction) {
      if (transaction.session_id) this.$emit("session", transaction.session_id);
      else this.$emit("copy", transaction.id)
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "loadTransactions" || action.type === "setTime") this.getTransactions();
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>

<style lang="scss">
.v-list--two-line .v-list__tile {
  height: 62px !important;
}

.transaction {
  cursor: auto;
  box-shadow: none;
  background: #f8f8f8;
}
.wallet-transaction {
  &__wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
}
h2 {
  font-weight: 500;
}
small {
  font-size: 12px;
}
.date {
  font-size: 0.9em;
}

.v-list__tile__action-text {
  white-space: nowrap;
}
</style>
