<template>
    <v-btn v-if="username" class="plugzio-button" outline round small :href="mailto" target="_blank">E-MAIL AN INVITATION</v-btn>
</template>

<script>
export default {
    props: ["username"],
    computed: {
        mailto() {
            if (this.username) {
                let subject = "EV Charger – Final Step – Needs Attention";
                let body = `
Hello,

We are almost done setting up your EV-ready parking space. As the final step you need to create a new user account with Plugzio by going to https://my.plugz.io/#/register and following the on-screen steps to log in. Here is a comprehensive tutorial to support your registration process: https://intercom.help/plugzio/en/articles/4389273-user-registration

Please reply to this email as soon as your account is created so we can grant you access to your specific Plugzio unit.
Note: If your Plugzio account is registered under a different email address than this one, please let us know in your reply.

After we grant you access, you can start accessing electricity from your Plugzio unit by inserting the Device ID (located at the front of the outlet) and confirming the rate of usage. The device ID will be saved on your user dashboard to save you time from having to enter it again later.
Note: You may be guided to the wallet page to fill up your Plugzio wallet in case there is a fee set for usage.

Thanks,

Plugzio Manager of the property  
	      		`;

                return `mailto:${this.username}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
            }

            return "#";
        },
    },
    methods: {
        sendEmail() {},
    },
};
</script>
