<template>
  <v-flex>
    <v-card class="ma-2">
      <v-card-title>
        <h3 id="activated-bluetooth-device-tile">Activated Bluetooth Devices</h3>
        <VTooltipHandler id="activated-bluetooth-device-info" classes="ml-2" icon="info" text="Recent devices that have an ongoing session and were activated by Bluetooth." />
      </v-card-title>
      <v-card-text class="session-list">
        <div class="text-xs-center mt-3" v-if="activatedBleDevices.length == 0">
          <h4 class="">No Activated Bluetooth Devices</h4>
        </div>
        <v-list id="activated-bluetooth-device-list" three-line>
          <v-list-tile
            avatar
            class="session-tile recent-plugs flex-col-on-xs"
            :class="{ 'session-active': device.device_id == bleActivation.connectedPlugIdentifier }"
            v-for="(device, index) in activatedBleDevices"
            :key="index"
          >
            <v-flex xs12 class="activated-ble-tile__details flex-row d-flex">
              <v-list-tile-avatar class="activated-ble-tile__avatar">
                <div class="plug-avatar">
                  <img src="./../../assets/plug-on.png" v-if="device.device_id == bleActivation.connectedPlugIdentifier" />
                  <img src="./../../assets/plug-off.png" v-else />
                </div>
              </v-list-tile-avatar>
              <v-list-tile-content class="plug-tile-details">
                <div>
                  Device ID:
                  <span class="black--text">
                    {{ device.device_id }}
                  </span>
                </div>
                <div class="mt-1">
                  <h2>
                    <span class="black--text">
                      <template v-if="device.session_active">
                        Start Time:
                        {{ device.startTime ? formatDateTime(device.startTime) : "-" }}
                      </template>
                      <template v-else>
                        End Time:
                        {{ device.endTime ? formatDateTime(device.endTime) : "-" }}
                      </template>
                    </span>
                  </h2>
                </div>
              </v-list-tile-content>
            </v-flex>
            <v-list-tile-action class="activated-ble-tile__action">
              <v-btn
                :loading="bleActivation.activeBleDevice == device.device_id"
                @click="prepareAndConnectToBluetoothDevice(device.device_id)"
                small
                class="plugzio-button session-button"
                outline
                round
                :disabled="role == 'admin'"
              >
                <span class="pa-2">
                  {{ device.device_id == bleActivation.connectedPlugIdentifier ? "Stop Session" : "Connect" }}
                </span>
              </v-btn>
              <v-btn
                :loading="bleActivation.activeBleDevice == device.device_id"
                @click="removeBleDevice(device.device_id)"
                small
                class="plugzio-danger-button session-button mt-2 p-2"
                outline
                round
                :disabled="role == 'admin'"
              >
                <span class="pa-2"> Remove From List</span>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import mixins from "@/mixins";
import helpers from "@/mixins/helpers";
import bleHandler from "@/mixins/ble/bleHandler";
import Api from "@/library/apis/Api";
import { mapState, mapGetters } from "vuex";
import VTooltipHandler from "@/components/VTooltipHandler";
import AccessPlan from "@/components/AccessPlan";
export default {
  mixins: [mixins, bleHandler, helpers],
  components: {
    AccessPlan,
    VTooltipHandler,
  },
  data() {
    return {
      actionSub: null,
    };
  },
  watch: {},
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
    }),
    ...mapGetters(["storedTime"]),
    ...mapGetters("Admin", {
      adminViewAsUser: "viewAsUser",
    }),
    activatedBleDevices() {
      return this.storedBleDevices.filter((device) => device.activated == true);
    },
    storedBleDevices() {
      return this.$store.getters.bleDevices;
    },
    sessionLogs() {
      return this.$store.state.sessions;
    },
  },
  methods: {
    prepareAndConnectToBluetoothDevice(device_id) {
      if (device_id != this.bleActivation.plug_identifier && this.bleActivation.deviceConnected) {
        this.$store.dispatch("setBleActivation", { busy: false });
        this.bleActivation.device.gatt.disconnect();
      }

      const bleDevice = this.storedBleDevices.filter((device) => device.device_id == device_id)[0];
      let modalLoading = "Starting Session";
      let activeProcess = this.bleProcesses.START_SESSION;
      if (bleDevice.session_active && this.bleActivation.connectedPlugIdentifier == device_id) {
        modalLoading = "Stopping Session";
        activeProcess = this.bleProcesses.STOP_SESSION;
      }
      this.$store.dispatch("setBleActivationModal", {
        title: "Connecting To Device",
        show: true,
        loading: modalLoading,
        error: null,
        okButton: null,
        cancelButton: null,
      });
      this.$store.dispatch("setBleActivation", {
        activeProcess: activeProcess,
        plug_identifier: device_id,
        tokens: bleDevice.credential,
        activeBleDevice: device_id,
        forceStopProcess: false,
        retry: 0,
      });
      this.$nextTick(() => {
        this.$router.push({ path: `/activate-device/` + device_id, query: { directlyToBle: true } });
        // this.connectToBleDevice(device_id);
      });
    },
    removeBleDevice(device_id) {
      for (let index = 0; index < this.storedBleDevices.length; index++) {
        const device = this.storedBleDevices[index];
        if (device.device_id == device_id) {
          if (!device.session_active || this.bleActivation.connectedPlugIdentifier != device.device_id) {
            this.storedBleDevices[index].activated = false;
            this.$store.dispatch("setBleDevices", this.storedBleDevices);
            this.$store.dispatch("setBleActivation", { busy: false });
            break;
          }

          this.$store.dispatch("setBleActivationModal", { title: "Connecting To Device", show: true, loading: "Stopping Session" });
          this.$store.dispatch("setBleActivation", {
            activeProcess: this.bleProcesses.REMOVE_DEVICE,
            plug_identifier: device_id,
            tokens: device.credential,
            activeBleDevice: device_id,
            forceStopProcess: false,
            retry: 0,
          });
          this.$router.push({ path: `/activate-device/` + device_id, query: { directlyToBle: true } });
          // this.connectToBleDevice(device_id);
          break;
        }
      }
    },
  },
  mounted() {
    const _this = this;
    function initStoredBleDevices() {
      let cache = JSON.parse(localStorage.getItem("__plugzio_storage__"));
      if (!cache) return;
      const email = _this.role != "admin" ? localStorage.getItem("email") : _this.adminViewAsUser.username;
      let bleDevices = typeof cache[email] != "undefined" && cache[email].hasOwnProperty("bleDevices") ? cache[email].bleDevices : [];
      _this.$store.dispatch("setBleDevices", bleDevices);
    }
    initStoredBleDevices();

    async function checkDevicesToken() {
      const devices = _this.storedBleDevices;
      let sessionLogs = _this.sessionLogs;
      if (navigator.onLine) {
        // console.groupCollapsed("Getting 30 days session logs for checking BLE token");
        const time = {
          since: _this
            .$moment()
            .subtract(30, "days")
            .startOf("day")
            .unix(),
          till: _this
            .$moment()
            .endOf("day")
            .unix(),
        };
        const apiPrefix = _this.role == "admin" ? "admin/user" : "user";
        const username = _this.role == "admin" ? _this.adminViewAsUser.username : null;
        await Api.sessions(apiPrefix, { since: _this.storedTime.since, till: _this.storedTime.till, username })
          .then((data) => {
            // console.log("Successfully get session logs");
            sessionLogs = data;
          })
          .catch((error) => {
            console.log(error);
            // console.log("Failed getting session logs. Using existing session logs.");
          });
        // console.log(sessionLogs);
        // console.groupEnd();
      }
      for (let index = 0; index < devices.length; index++) {
        const device = devices[index];
        console.groupEnd();
        console.groupCollapsed("Checking token for device: " + device.device_id);

        if (!device.credential) {
          console.log("Device not have token. No Actions processed.");
          continue;
        }

        const is_token_expired = _this.isBleTokenExpired(device.credential);
        if (!(await _this.isBleTokenUsedRecently(device.device_id, false, sessionLogs))) {
          console.log("Token not used recently.");

          if (!is_token_expired) {
            console.log("Token not expired yet. No Actions processed.");
            continue;
          }

          console.log("Token is expired.");
          _this.$store.dispatch("setBleCredential", { device_id: device.device_id, bleCredential: null });
          console.log("Token removed from device.");

          continue;
        }

        console.log("Token recently used.");

        if (!is_token_expired && !_this.isBleTokenExpiringSoon(device.credential)) {
          console.log("Token not expired / expiring soon. No Actions processed.");
          continue;
        }

        console.log("Token is expired / expiring soon. Renewing token.");
        const renewTokenReturn = await _this.renewBleToken(device.device_id);
        if (renewTokenReturn.error) {
          console.error(renewTokenReturn.message);
          continue;
        }
        console.log("Successfully renew token.");
      }
      console.groupEnd();
    }
    this.$nextTick(() => {
      checkDevicesToken();
    });

    // this.$store.dispatch("setBleActivation", { activeBleDevice: null, forceStopProcess: false, forceConnectManually: false });
    this.$store.dispatch("setBleActivation", { activeBleDevice: null, forceConnectManually: false });
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type == "setBleActivationModal") {
        if (action.payload.show == false) {
          this.$store.dispatch("setBleActivation", { activeBleDevice: null, forceStopProcess: false, forceConnectManually: false });
        }
        this.$forceUpdate();
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>
<style lang="scss">
.hide-on-xs {
  @media only screen and (max-width: 620px) {
    display: none;
  }
}
.flex-col-on-xs {
  .v-list__tile {
    @media only screen and (max-width: 620px) {
      flex-direction: column;
    }
  }
}
.flex-row {
  flex-direction: row;
}
.activated-ble-tile {
  &__details {
    @media only screen and (max-width: 620px) {
      width: 100%;
    }
  }
  &__avatar {
    @media only screen and (max-width: 620px) {
      margin-top: 0px !important;
      .v-avatar {
        .plug-avatar {
          margin-top: 0px !important;
        }
      }
    }
  }
  &__action {
    flex-grow: 1;
    @media only screen and (max-width: 620px) {
      flex-direction: row;
      margin-top: 4px;
      width: 100%;
    }
  }
}
</style>
