<template>
  <div class="time-based-billing-information" :class="{ 'time-based-billing-information__plain': plain }">
    <v-card
      class="subcard subcard__levelled mb-4"
      :class="[i_unless > 1 ? `mt-2` : `mt-4`, `subcard__levelled__level${depth}`]"
      :key="i_unless"
      v-for="(unless, i_unless) in data.unless"
    >
      <v-card-text>
        <v-layout>
          <v-layout column justify-center>
            <v-flex xs12 align-center class="text-xs-left" :class="{ 'd-flex': !!unless.plan.disabled }">
              <p :class="{ 'mb-2': !unless.plan.disabled, 'mb-0': !!unless.plan.disabled }">
                <PaymentPlanText
                  :type="getType(unless.condition)"
                  :value="unless.condition"
                  :device-disabled="!!unless.plan.disabled"
                  :is-user="isUser"
                  :restrictSame="!getType(unless.condition) === 'day'"
                  use-charge-text
                />
              </p>
              <template v-if="!unless.plan.disabled">
                <p class="mb-1">
                  Activation Fee ({{ data.selectedWalletCurrency }}):
                  <strong class="ml-1">{{ formatCost(parseFloat(unless.plan.startSessionCost || 0)) }}</strong>
                </p>
                <p class="mb-1">
                  Duration Rate ({{ data.selectedWalletCurrency }}):
                  <strong class="ml-1">{{ formatRate(parseFloat(unless.plan.hRate || 0)) }}</strong>
                </p>
                <p class="mb-1">
                  Consumption Rate ({{ data.selectedWalletCurrency }}):
                  <strong class="ml-1">
                    {{ formatRate((parseFloat(unless.plan.whRate || 0) * 1000000000) / 1000000, "kWh") }}
                  </strong>
                </p>
              </template>
            </v-flex>
            <TimeBasedBillingInformation
              v-if="unless.plan.hasOwnProperty('unless') && !!unless.plan.unless && unless.plan.unless.length > 0"
              :key="timebasedBillingKey"
              :data="{ unless: unless.plan.unless, selectedWalletCurrency: data.selectedWalletCurrency }"
              :allow-add-new-rule="false"
              @addSubRule="(indexes) => $emit('addSubRule', [i_unless].concat(indexes))"
              @update="(new_unless) => onSubRuleUpdate(i_unless, new_unless)"
              @edit="(params) => editSubRule(i_unless, params)"
              :depth="depth + 1"
              :plain="plain"
              :show-actions="showActions"
              :is-user="isUser"
            />
          </v-layout>
          <div v-if="showActions" style="white-space:nowrap">
            <v-hover v-if="(!unless.plan.unless || unless.plan.unless.length < max_rule) && depth < max_depth">
              <button @click="$emit('addSubRule', [i_unless])" class="button-onlyicon button-plugzio mr-1" slot-scope="{ hover }">
                <v-scale-transition group>
                  <v-icon size="18" style="margin: 0" key="icon">add</v-icon>
                  <span v-if="hover" class="ml-1" key="description">Add Sub Rule</span>
                </v-scale-transition>
              </button>
            </v-hover>
            <v-menu transition="slide-y-transition">
              <template v-slot:activator="{ on }">
                <button v-on="on" class="button-onlyicon">
                  <v-icon size="18" style="margin: 0" key="icon">
                    keyboard_arrow_down
                  </v-icon>
                </button>
              </template>
              <v-list class="v-list__actions">
                <v-list-tile @click="edit(i_unless, getType(unless.condition))">
                  <v-list-tile-title>Edit</v-list-tile-title>
                </v-list-tile>
                <template v-if="data.unless.length > 1">
                  <v-divider></v-divider>
                  <v-list-tile v-if="i_unless > 0" @click="moveUp(i_unless)">
                    <v-list-tile-title>Move Up</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile v-if="i_unless < data.unless.length - 1" @click="moveDown(i_unless)">
                    <v-list-tile-title>Move Down</v-list-tile-title>
                  </v-list-tile>
                </template>
                <v-divider></v-divider>
                <v-list-tile @click="showDeleteConfirmation(i_unless)">
                  <v-list-tile-title>Delete</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
    <div v-if="allowAddNewRule && data.unless.length < max_rule" class="mt-4">
      <v-btn class="plugzio-button" outline round @click="$emit('addNewTimeBasedRule')">
        <v-icon class="mr-1">
          add
        </v-icon>
        Add new conditional rule
      </v-btn>
    </div>

    <v-dialog v-model="show_delete_confirmation" max-width="300">
      <v-card>
        <v-card-title>
          <h3>Delete Time-Based Rule</h3>
        </v-card-title>
        <v-card-text class="text-xs-justify">
          <v-flex class="flex flex-column items-center">
            <p>
              Deleting this rule will also delete all sub rules. Please confirm if you would like to proceed.
            </p>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" @click="show_delete_confirmation = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" flat="flat" @click="confirmDelete()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import helpers from "@/mixins/helpers";
import { v4 as uuidv } from "uuid";
import { getConditionalType } from "@/library/helpers/paymentPlan"
import TimeBasedBillingInformation from "@components/TimeBased/TimeBasedBillingInformation";
import PaymentPlanText from "@components/payment-plan/PaymentPlanText";

export default {
  name: "TimeBasedBillingInformation",
  mixins: [helpers],
  components: { TimeBasedBillingInformation, PaymentPlanText },
  props: {
    data: { type: Object, required: true },
    allowAddNewRule: { default: true, type: Boolean },
    depth: { default: 0, type: Number },
    showActions: { default: true, type: Boolean },
    plain: { type: Boolean, default: false },
    isUser: { type: Boolean, default: false }
  },
  data: () => ({
    max_rule: 3,
    max_depth: 3,
    show_delete_confirmation: false,
    delete_index: null,
    timebasedBillingKey: null,
  }),
  methods: {
    showDeleteConfirmation(index) {
      this.show_delete_confirmation = true;
      this.delete_index = index;
    },
    confirmDelete() {
      const new_unless = this.data.unless;
      new_unless.splice(this.delete_index, 1);
      this.delete_index = null;
      this.show_delete_confirmation = false;
      this.$emit("update", new_unless);
    },
    edit(index, type) {
      const params = this.data.unless[index];

      this.$emit("edit", {
        indexes: [index],
        type: type,
        params: {
          condition: params.condition,
          plan: {
            disabled: !!params.plan.disabled,
            hRate: params.plan.hRate ? params.plan.hRate : null,
            startSessionCost: params.plan.startSessionCost ? params.plan.startSessionCost : null,
            whRate: params.plan.whRate ? (params.plan.whRate * 1000000000) / 1000000 : null,
          },
        },
      });
    },
    editSubRule(index, params) {
      params.indexes = [index].concat(params.indexes);
      this.$emit("edit", params);
    },
    formatTwoDigitTime(time) {
      if (!time) return "00";
      if (time < 10) return "0" + time;
      return time;
    },
    getType: getConditionalType,
    moveUp(index) {
      let unless = this.data.unless;
      const upper_unless = unless[index - 1];
      const current_unless = unless[index];
      unless[index - 1] = current_unless;
      unless[index] = upper_unless;
      this.$emit("update", unless);
    },
    moveDown(index) {
      let unless = this.data.unless;
      const lower_unless = unless[index + 1];
      unless[index + 1] = unless[index];
      unless[index] = lower_unless;
      this.$emit("update", unless);
    },
    onSubRuleUpdate(index, new_unless) {
      let unless = this.data.unless;
      unless[index].plan.unless = new_unless;
      this.timebasedBillingKey = uuidv();
      this.$emit("update", unless);
    },
  },
  created() {
    if (this.data && this.data.hasOwnProperty("unless")) {
      if (!this.data.unless) this.data.unless = [];
      for (let i = 0; i < this.data.unless.length; i++) {
        const unless = this.data.unless[i];
        if (unless.plan.hasOwnProperty("unless") && !unless.plan.unless) this.data.unless[i].plan.unless = [];
      }
    }
  },
};
</script>
<style lang="scss">
.v-list__actions {
  .v-list__tile {
    cursor: pointer;
    &:hover {
      background-color: #e4e0e0;
    }
  }
}
.subcard {
  &__levelled {
    border-left: 4px solid lightgray;
    border-radius: 4px;
    &__level0 {
      border-color: lightgray !important;
    }
    &__level1 {
      border-color: #4caf50 !important;
    }
    &__level2 {
      border-color: rgb(87, 87, 253) !important;
    }
    &__level3 {
      border-color: orange !important;
    }
    &__level4 {
      border-color: papayawhip !important;
    }
    &__level5 {
      border-color: yellow !important;
    }
    &__level6 {
      border-color: rgb(255, 87, 87) !important;
    }
  }
}
.time-based-billing-information {
  &__plain > .subcard {
    box-shadow: none !important;
  }
}
</style>
