<template>
  <v-card v-if="userAccess">
    <v-card-title>
      <h3>Device Activation</h3>
    </v-card-title>
    <v-card-text class="mt-1">
      <v-card class="subcard mb-4">
        <v-card-text>
          <v-layout>
            <v-layout column>
              <v-flex v-if="!userAccess.accessPlan.disabled" xs12 class="text-xs-left">
                <h5 class="mb-2">
                  Default Billing
                </h5>
                <p class="mb-1">
                  Activation Fee ({{ userAccess.currency }}):
                  <strong class="ml-1">{{ formatCost(parseFloat(userAccess.accessPlan.startSessionCost || 0)) }}</strong>
                </p>
                <p class="mb-1">
                  Duration Rate ({{ userAccess.currency }}):
                  <strong class="ml-1">{{ formatRate(parseFloat(userAccess.accessPlan.hRate || 0)) }}</strong>
                </p>
                <p class="mb-1">
                  Consumption Rate ({{ userAccess.currency }}):
                  <strong class="ml-1">
                    {{ formatRate(parseFloat(userAccess.accessPlan.whRate || 0), "kWh") }}
                  </strong>
                </p>
              </v-flex>

              <v-flex v-else xs12 align-center class="d-flex text-xs-left">
                <p class="mb-0">Device is <strong>disabled</strong> by default</p>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
      <TimeBasedBillingInformation
        :allow-add-new-rule="false"
        :show-actions="false"
        :data="{ unless: userAccess.accessPlan.unless, selectedWalletCurrency: userAccess.currency }"
        :is-user="true"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn id="device-activation-back-button" color="gray" flat="flat" @click="$emit('close')">BACK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TimeBasedBillingInformation from "@/components/TimeBased/TimeBasedBillingInformation.vue";
import { formatRate, formatCost } from "@/library/helpers";
export default {
  name: "TimeBasedBillingDetail",
  props: {
    userAccess: {
      type: Object,
      required: true
    }
  },
  components: {
    TimeBasedBillingInformation
  },
  methods: {
    formatRate,
    formatCost
  }
}
</script>