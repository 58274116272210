<template>
  <v-tooltip top v-model="show" content-class="mx-1">
    <template v-slot:activator="{}">
      <v-icon :id="id" class="tooltip-icon" :class="classes" @click.stop.prevent="show = true" @mouseleave="show = false" @mouseenter="show = true">
        {{ icon }}
      </v-icon>
    </template>
    <span v-html="text" />
  </v-tooltip>
</template>

<script>
export default {
  name: "VTooltipHandler",
  props: {
    id: String,
    icon: String,
    classes: String,
    text: String,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    show(val) {
      if (val) {
        if (this.$gtm) {
          this.$gtm.trackEvent({
            event: "Custom Hover Event",
            "custom-hover-event": "tooltip-hover",
            "custom-hover-event-text": this.text,
          });
        }
      }
    },
  },
};
</script>
