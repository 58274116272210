<template>
  <v-card class="ma-2">
    <v-card-title
      class="px-0"
      @click.stop="expand = !expand"
    >
      <v-flex class="d-flex" justify-space-between w-full text-xs-center>
        <h3 class="w-1/4">Device Information</h3>
        <h3 class="w-1/4">Inventory Status</h3>
        <h3 class="w-1/4">Manager Configuration</h3>
        <h3 class="w-1/4">Subscription Configuration</h3>
      </v-flex>
      <v-spacer />
      <v-btn
        flat
        small
        icon
        class="ma-0"
        absolute
        style="right: 1rem;"
        @click.stop="expand = !expand"
      >
        <v-icon v-if="expand">keyboard_arrow_up</v-icon>
        <v-icon v-else>keyboard_arrow_down</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="expand" class="pa-0">
      <v-flex class="d-flex" justify-space-between w-full>
        <v-layout
          v-for="(infos, index) in informations"
          :key="index"
          column
          pt-1
          class="info-container w-1/4"
        >
          <v-flex
            v-for="(info, i) in infos"
            :key="i"
            d-flex
            class="w-full"
          >
            <strong class="text--muted">{{ info.text }}: </strong>
            <span
              class="text-xs-right"
              :class="{
                link: !!info.onClick
              }"
              v-html="!info.value || info.value === 'null' ? 'N/A' : info.value"
              @click.stop="() => info.onClick ? info.onClick() : defaultOnClick()"
            />
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!isDeviceRegistered"
          column
          pt-1
          class="info-container only-info w-1/4"
        >
          <v-flex
            d-flex
            class="w-full"
          >
            <strong class="text--muted">Device is not registered</strong>
          </v-flex>
        </v-layout>
        <v-layout
          v-if="!isDeviceRegistered"
          column
          pt-1
          class="info-container only-info w-1/4"
        >
          <v-flex
            d-flex
            class="w-full"
          >
            <strong class="text--muted">Device is not registered</strong>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex"
import { capitalize } from 'lodash-es'

export default {
  name: "DeviceInformationCard",
  props: {
    device: {
      default: {}
    }
  },
  data() {
    return {
      expand: true
    }
  },
  computed: {
    ...mapState({
      timezone: (state) => state.Global.timezone,
    }),
    isDeviceRegistered() {
      return this.device && this.device.id !== "not_registered"
    },
    informations() {
      const payAsYouGo = JSON.parse(
        this.device.dataplanConfig && this.device.dataplanConfig.pay_as_you_go
          ? this.device.dataplanConfig.pay_as_you_go
          : "{}"
      );

      const payAsYouGoCad = ((payAsYouGo || {}).priceList || []).find(p => p.currency === "CAD") || {}
      const payAsYouGoUsd = ((payAsYouGo || {}).priceList || []).find(p => p.currency === "USD") || {}

      const device = this.device || {}
      device.paygCad = payAsYouGoCad.cost || null
      device.paygUsd = payAsYouGoUsd.cost || null

      const informations = [
        [
          {
            text: "Model",
            value: device.model
          },
          {
            text: "Communication Type",
            value: device.communication
          },
          {
            text: "Max Current (A)",
            value: device.current
          },
          {
            text: "Voltage (V)",
            value: device.voltage
          },
          {
            text: "External ID",
            value: device.external_identifier
          },
          {
            text: "Internal ID",
            value: device.internal_identifier
          },
          {
            text: "Serial Number",
            value: device.serial_no
          },
          {
            text: "BLE Token",
            value: device.ble_token
          },
          {
            text: "Hardware Version",
            value: device.version
          },
          {
            text: "Last Heard Date",
            value: device.last_log ? this.$moment.unix(device.last_log.timestamp).tz(this.timezone).format("DD-MMM-YYYY hh:mm:ss A") : null
          }
        ],
        [
          {
            text: "Inventory Status",
            value: capitalize(device.status_name || "")

          },
          {
            text: "Inventory Memo",
            value: device.memo
          },
          {
            text: "Manufactured Date",
            value: this.$moment.unix(device.manufactured_date).tz(this.timezone).format("DD-MMM-YYYY hh:mm:ss A")
          },
          {
            text: "PCB Batch",
            value: device.pcb_batch
          },
        ],
      ]
    
      if (this.isDeviceRegistered) {
        informations.push([
          {
            text: "Description",
            value: device.description
          },
          {
            text: "Power Setting",
            value: `${device.mode}${device.mode === "Smart" ? ` (${device.access_settings})` : ''}`
          },
          {
            text: "Hidden",
            value: (device.ownerPrefs && device.ownerPrefs.hiddenDevices && device.ownerPrefs.hiddenDevices.includes(device.identifier)) ? 'Yes' : 'No'
          },
          {
            text: "Manager",
            value: device.owner_username,
            onClick: device.owner_username ? () => this.$router.push({ name: "admin-managerview", query: { search: device.owner_username } }): () => { console.log("owner username not found", device.owner_username) }
          },
        ])
      
        informations.push([
          {
            text: "Registration Date",
            value: this.$moment.unix(device.start_timestamp).tz(this.timezone).format("DD-MMM-YYYY hh:mm:ss A")
          },
          {
            text: "Expiry Date",
            value: this.$moment.unix(device.expiry_timestamp).tz(this.timezone).format("DD-MMM-YYYY hh:mm:ss A")
          },
          {
            text: "Subscription Time",
            value: device.remaining,
            onClick: () => this.$router.push({ name: "admin-configure-device-subscriptions", query: { device: device.identifier } })
          },
          {
            text: "Monthly Fee",
            value: `
            <div style="text-align: center;">
              <span style="border-right: 1px solid #9e9e9e; padding-right: 5px;">
                ${device.dataplanConfig.cost_usd ? `$ ${device.dataplanConfig.cost_usd} USD` : "N/A"}
              </span> 
              <span style="margin-left: 5px; padding-right: 5px;">
                ${device.dataplanConfig.cost_cad ? `$ ${device.dataplanConfig.cost_cad} CAD` : "N/A"}
              </span>
            </div>
            `
          },
          {
            text: "Pay As You Go Fee",
            value: `
            <div style="text-align: center;">
              <span style="border-right: 1px solid #9e9e9e; padding-right: 5px;">
                ${device.paygUsd !== null ? `$ ${device.paygUsd} USD` : "N/A"}
              </span> 
              <span style="margin-left: 5px; padding-right: ${device.paygCad !== null ? '19px' : '45px'};">
                ${device.paygCad !== null ? `$ ${device.paygCad} CAD` : "N/A"}
              </span>
            </div>
            `
          },
          {
            text: "Initial Registration Time",
            value: device.dataplanConfig.timespan ? `${Math.floor(device.dataplanConfig.timespan / (24 * 60 * 60))} Days`  : "",
            onClick: () => this.$router.push({ name: "admin-configure-device-subscriptions-plan", query: { device: device.identifier } })
          },
         
          {
            text: "Auto Payment Status",
            value: `<span class="${device.auto === 'ON' ? 'green--text' : 'red--text'}">${device.auto}</span>`
          },
          {
            text: "Wallet",
            value: device.wallet ? device.wallet.description : ""
          },
          {
            text: "Service Tier",
            value: device.service_tier_id
          }
        ])
      }
      return informations
    }
  },
  methods: {
    defaultOnClick() {
      console.log("default on click handler")
    },
  }
}
</script>

<style lang="scss" scoped>
.info-container {
  border: 1px solid #9e9e9e;
  row-gap: 4px;
  div {
    max-height: 24px;
    border-bottom: 1px solid lighten(#9e9e9e, 20%);
    padding-bottom: 1px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    * {
      height: 22px;
    }
  }
  &.only-info {
    align-items: center;
    display: flex;
    justify-content: center;
    div {
      border-bottom: 0px !important;
      * {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
