<template>
  <div :key="tileRefreshKey">
    <agile v-if="ready" ref="walletCarousel" autoplay :options="options">
      <div
        class="slide pointer"
        :class="`slide--${index + 1}`"
        :key="index"
        ripple
        v-for="(wallet, index) in groupedWalletItems"
        @click.stop="openDetails(walletAmountKey, wallet)"
      >
        <v-card-text class="d-flex">
          <h3>{{ texts.title }} ({{ wallet.currency }})</h3>
          <v-spacer></v-spacer>
          <div class="text-xs-right">
            <VTooltipHandler :id="`total-${walletAmountKey}-tile-info`" classes="stat-tooltip" icon="info" :text="texts.tooltip" />
          </div>
        </v-card-text>
        <v-card-text class="py-1 text-xs-right">
          <h2 v-if="loading">
            <v-progress-circular indeterminate color="white" :size="24"></v-progress-circular>
          </h2>
          <div v-else>
            <h2 :id="`total-${walletAmountKey}-tile`">
              {{ wallet[walletAmountKey] | numeral("0,0.00") }}
            </h2>
          </div>
        </v-card-text>
      </div>
      <template slot="prevButton">
        <v-icon :id="`total-${walletAmountKey}-tile-previous-button`">navigate_before</v-icon>
      </template>
      <template slot="nextButton">
        <v-icon :id="`total-${walletAmountKey}-tile-next-button`">navigate_next</v-icon>
      </template>
    </agile>
    <TotalStatsGraph :selected="selectedStat" />
  </div>
</template>

<script>
import TotalStatsGraph from "./modals/TotalStatsGraph";
import VTooltipHandler from "./VTooltipHandler";

export default {
  props: ["walletItems", "loading", "title", "tooltip", "amountKey"],
  components: {
    TotalStatsGraph,
    VTooltipHandler,
  },
  data() {
    return {
      ready: false,
      selectedStat: {},
      options: {
        autoplay: true,
        autoplaySpeed: window.CAROUSEL_TIME,
      },
      walletAmountKey: "revenue",
      texts: {
        title: "Total Revenue",
        tooltip: "Total earnings from all user sessions<br>over the selected filter and date range",
      },
    };
  },
  watch: {
    walletItems() {
      this.$nextTick(() => {
        if (this.walletItems.length > 0) {
          setTimeout(() => {
            this.ready = true;
          }, 500);
        }
      });
    },
  },
  computed: {
    groupedWalletItems() {
      let groupedWalletItems = [];
      for (let index = 0; index < this.walletItems.length; index++) {
        const walletItem = this.walletItems[index];
        const groupedWalletItemCurrencies = groupedWalletItems.map((groupedWalletItem) => groupedWalletItem.currency);
        const indexOfWallet = groupedWalletItemCurrencies.indexOf(walletItem.currency);
        if (indexOfWallet > -1) {
          groupedWalletItems[indexOfWallet].grouped_ids.push(walletItem.id);
          groupedWalletItems[indexOfWallet].balance += walletItem.balance;
          groupedWalletItems[indexOfWallet].revenue += walletItem.revenue;
          groupedWalletItems[indexOfWallet].expense += walletItem.expense;
        } else {
          walletItem.grouped_ids = [walletItem.id];
          groupedWalletItems.push(walletItem);
        }
      }
      return groupedWalletItems;
    },
    tileRefreshKey() {
      return this.groupedWalletItems.map((wallet) => wallet.id).join("-");
    },
  },
  methods: {
    openDetails(field, wallet) {
      this.selectedStat = {};
      const name = this.walletAmountKey.charAt(0).toUpperCase() + this.walletAmountKey.slice(1);
      this.$nextTick(() => {
        this.selectedStat = {
          name: name,
          field,
          wallet,
        };
      });
    },
  },
  mounted() {
    if (this.walletItems.length > 0) {
      setTimeout(() => {
        this.ready = true;
      }, 500);
    }
    if (typeof this.title != "undefined") {
      this.texts.title = this.title;
    }
    if (typeof this.tooltip != "undefined") {
      this.texts.tooltip = this.tooltip;
    }
    if (typeof this.amountKey != "undefined") {
      this.walletAmountKey = this.amountKey;
    }
  },
};
</script>

<style lang="css">
.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 25px;
}

.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.agile__nav-button--prev {
  left: 0;
}

.agile__nav-button--next {
  right: 0;
}

.agile__dots {
  bottom: 4px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.agile__dot {
  margin: 0 4px;
}

.agile__dot button {
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 8px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 50%;
  transform: translateX(50%);
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff !important;
}

.slide {
  display: block;
  width: 100%;
}

@media screen and (max-width: 650px) {
  .agile__dots {
    visibility: hidden;
  }
}
</style>
