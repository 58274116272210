<template>
  <v-card >
    <v-card-title>
      <h3>Device Activation</h3>
    </v-card-title>
    <v-card-text class="mt-1">
      <div class="text-xs-center">
        <h4>Automatically Starting Sessions</h4>
        <ul id="device-activation-auto-start-message-1" class="text-xs-justify mt-2">
          <li class="mb-1">Enabling this feature keeps the Plugzio device on and ready to be used immediately.</li>
          <li class="mb-1">This allows sessions to automatically begin the moment you plug into the device for power.</li>
          <li class="mb-1">You will be able to use the Plugzio device without the hassle of activation</li>
          <li>Manually stopping a session through the dashboard will require you to manually start the session.</li>
        </ul>
        <v-divider class="my-4"></v-divider>
        <h4 class="red--text">RISKS & RESPONSIBILITY</h4>
        <ul id="device-activation-auto-start-message-2" class="text-xs-justify mt-2">
          <li class="mb-1">With easy access comes less security, auto activation means that anyone can plug in at your expense.</li>
          <li class="mb-1">
            Enabling sessions to automatically start means that you be responsible for and billed for any and all consumption of this device without your authentication
          </li>
          <li class="mb-1">You will be billed at the rate specified by the manager's payment plan.</li>
          <li>
            You agree to automatically accept any payment plan set by the device manager. It is your responsibility to follow up with the manager regarding any payment
            issues.
          </li>
        </ul>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn id="device-activation-back-button" color="gray" flat="flat" @click="$emit('close')">BACK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AutoStartSessionCard"
}
</script>