<template>
  <v-card class="ma-2">
    <v-card-title>
      <h3 id="settings-tile">Settings</h3>
    </v-card-title>
    <v-card-text class="text-xs-center">
      <v-form ref="settingsForm">
        <v-flex items-center d-flex>
          <span class="white-space__nowrap">
            Dark Theme
          </span>
          <v-checkbox
            class="d-inline-flex mt-0 pt-0 w-full ml-4"
            style="padding-right: 1px"
            color="green"
            v-model="darkTheme"
            hide-details
            :disabled="!hasAccess"
          />
        </v-flex>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from "../library/apis/Api";
import { mapState } from "vuex"
import { debounce } from 'lodash-es';
import { ApiHelpers, OwnerProxyHelpers } from '@/library/helpers';

export default {
  name: "SettingsCard",
  computed: {
    ...mapState({
      role: (state) => state.Auth.role,
      preferences: state => state.Global.preferences || {},
      activeAdminView: (state) => state.Admin.activeView,
    }),
    settings() {
      return this.preferences.settings || {}
    },
    darkTheme: {
      get() {
        return this.settings.darkTheme
      },
      set(v) {
        const settings = {
          darkTheme: v
        }
        this.$store.commit("Global/setState", { preferences: { ...this.preferences, settings } })
      }
    },
    hasAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("SETTING_SETTINGS")
    }
  },
  watch: {
    darkTheme() {
      this.debouncedUpdate()
    }
  },
  created() {
    this.debouncedUpdate = debounce(() => {
      const payload = JSON.stringify({ prefs: this.preferences })

      return Api.setPrefs(ApiHelpers.getApiPrefix(this.role), payload)
    }, 500)
  },
};
</script>
